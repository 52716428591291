import React from 'react';
import { Box, Typography, Button, InputLabel } from '@mui/material';
import MultipleSelectWithChips from "../../../components/src/MultipleSelect";
import { Link } from 'react-router-dom';

interface Option {
    id: string;
    label: string;
}
interface AccountSetupStep1Props {
    onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void; // Define the type for onSubmit
    formValues: any;
    onChangeItems: (name: string, value:  string[]) => void
}
class AccountSetupStep1 extends React.Component<AccountSetupStep1Props> {
    constructor(props: AccountSetupStep1Props) {
        super(props);

    }
    render() {
        const { onSubmit, formValues, onChangeItems } = this.props;
        return (
            <Box sx={webStyle.formWrapper} data-test-id='account-inprogress'>

                <Box sx={webStyle.inputWrapper}>
                    <Typography variant="h6" sx={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 700, fontSize: "22px", color: "#001C55",
                        // textAlign: 'center'

                    }}>
                        Congrats!
                    </Typography>
                    <Typography variant="h6" sx={{
                        margin: '15px 0px',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 400, fontSize: "16px", color: "#0F172A",
                        // textAlign: 'center'
                    }}>
                        Now, let’s complete your setup:
                    </Typography>
                    <Typography variant="h6" sx={{
                        margin: '15px 0px',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 400, fontSize: "18px", color: "#5274B5",
                        // textAlign: 'center'
                    }}>
                        Where do you test?
                    </Typography>
                </Box>
                <Box sx={webStyle.inputWrapper}>
                    <InputLabel style={webStyle.label}>Airports</InputLabel>

                    <MultipleSelectWithChips options={[
                        { id: '1', label: 'JFK - John F. Kennedy International Airport' },
                        { id: '2', label: 'LAX - Los Angeles International Airport' },
                        { id: '3', label: 'ORD - O Hare International Airport' },
                        // Add more options as needed
                    ]}
                        name='airports'
                        selectedItems={formValues.airports}
                        placeholder="Enter the airports you serve" onItemsChange={onChangeItems} />
                </Box>
                {/* <Link to="/Login" > */}
                <Button
                    type="button"
                    onClick={onSubmit}
                    sx={webStyle.buttonStyle}
                    data-test-id="SubmitBtn"
                    variant="contained"
                >
                    Continue
                </Button>
                <Typography variant="h6" align="center" sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 400, fontSize: "16px", color: "#0F172A",
                    display: "inline"
                }}>

                    <Link to="/Login" style={{
                        fontFamily: 'Montserrat, sans-serif', display: "inline",
                        fontWeight: 700, fontSize: "16px", color: "#0074BF", textDecoration: "none",
                        marginLeft: "7px"
                    }}>
                        I’ll finish this later
                    </Link >
                </Typography>
                {/* </Link > */}
            </Box>
        );
    }
}

const webStyle = {
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        borderRadius: '26px',
        border: '1px solid rgb(252, 250, 250)',
        backgroundColor: 'rgb(255, 255, 255)',
        padding: '30px',
    },
    inputWrapper: {
        position: "relative",
        width: "100%",
        maxWidth: "360px",
        mb: 1,
    },
    title: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 700,
        fontSize: "22px",
        color: "#001C55",
    },
    subtitle: {
        margin: '15px 0px',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 400,
        fontSize: "16px",
        color: "#0F172A",
    },
    prompt: {
        margin: '15px 0px',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 400,
        fontSize: "18px",
        color: "#5274B5",
    },
    label: {
        fontSize: "16px",
        fontWeight: 600,
        marginTop: "3px",
        color: "#334155",
    },
    buttonStyle: {
        height: "50px",
        width: "360px",
        marginTop: "25px",
        marginBottom: "20px",
        border: "none",
        borderRadius: "20px",
        backgroundColor: "#0074BF",
        color: "white",
        cursor: "pointer",
        fontWeight: 700,
        textTransform: "none",
    },
    link: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 400,
        fontSize: "16px",
        color: "#0F172A",
        display: "inline",
    },
    linkStyle: {
        fontFamily: 'Montserrat, sans-serif',
        display: "inline",
        fontWeight: 700,
        fontSize: "16px",
        color: "#0074BF",
        textDecoration: "none",
        marginLeft: "7px",
    },
};

export default AccountSetupStep1;
