import React from 'react';
import { Box, Button, FormControlLabel, Checkbox, DialogContent, Dialog, DialogTitle, DialogActions, IconButton, InputLabel, Typography, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import MultipleSelectWithChips from "../../../components/src/MultipleSelect";
import { Link } from 'react-router-dom';
import MultipleSelectWithCheckBox from "../../../components/src/multiSelectCheckBox";
import { leftArrow, warning } from "./assets";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface AccountSetupStep1Props {
    formValues: any;
    onChangeItems: (name: string, value: string[]) => void;
    accordform1Expanded: boolean;
    accordform2Expanded: boolean;
    handleAccordform1Expanded: (name: string) => void;
    onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void; // Define the type for onSubmit

}
interface states {
    open: boolean,
    checked: boolean,
    expanded: boolean

}
class AccountSetupStep2 extends React.Component<AccountSetupStep1Props, states> {
    constructor(props: AccountSetupStep1Props) {
        super(props);
        this.state = {
            open: false,
            checked: false,
            expanded: false
        };
    }
    handleDeleteClick = (id: string) => {
        // Handle the deletion logic here
        this.setState((prevState) => ({
            open: true,
        }));
    };
    handleCloseDialog = () => {
        this.setState({ open: false });
    };

    handleConfirmDelete = () => {
        console.log(`Delete test with id:`);
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ checked: event.target.checked });
    };
    handleChanges = (event: React.SyntheticEvent, isExpanded: boolean) => {
        this.setState((prev) => ({ ...prev, expanded: !this.state.expanded })); // Set state based on accordion's expanded/collapsed state
    };
    render() {
        const { formValues, onSubmit, onChangeItems, accordform1Expanded } = this.props;
       
        return (
            <Box sx={webStyle.formWrapper}>
                <Box sx={webStyle.inputWrapper}>
                    <Typography variant="h6" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 700,
                        fontSize: "22px",
                        color: "#001C55",
                    }}>
                        <img src={leftArrow} style={{ padding: '7px', height: '21px', width: '24px' }} />
                        On the right track!
                    </Typography>

                    <Typography variant="h6" sx={{
                        margin: '15px 0px',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 400,
                        fontSize: "16px",
                        color: "#0F172A",
                    }}>
                        Let’s pick the tests you’ll be offering:
                    </Typography>
                    <Typography variant="h6" sx={{
                        margin: '15px 0px',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 400,
                        fontSize: "18px",
                        color: "#5274B5",
                    }}>
                        What types of tests do you do?
                    </Typography>
                </Box>

                <Grid container sx={{
                    minWidth: '800px'
                }}>
                    <Grid item xs={6} >
                        <Accordion sx={{ boxShadow: 'none', border: 'none' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{
                                    width: '20px',
                                    height: '20px',
                                    marginLeft: '20px',
                                    opacity: '0px', color: '#5274B5', backgroundColor: 'rgb(227, 231, 238);',
                                    borderRadius: '50%'
                                }} />}
                                aria-controls="panel2-content"
                                // IconButtonProps={{edge: 'start'}}
                                id="panel2-header"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse', // Use row-reverse to position items
                                    alignItems: 'center',
                                    padding: 0,
                                    // fontFamily:'sans-serif'
                                }}
                            >
                                <Typography sx={{
                                    fontFamily: 'Montserrat,sans-serif',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    textAlign: 'left',
                                    color: '#5274B5'
                                }}>Test specifications</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: 0 }}>
                                <Box sx={webStyle.inputWrapper}>
                                    <InputLabel style={webStyle.label}>Grade of certificate</InputLabel>
                                    <MultipleSelectWithCheckBox options={[
                                        { id: '1', label: 'JFK' },
                                        { id: '2', label: 'LAX' },
                                        { id: '3', label: 'ORD' },
                                        { id: '4', label: 'DFW' },
                                    ]}
                                        placeholder="Select a grade of certificate"
                                    />
                                </Box>
                                <Box sx={webStyle.inputWrapper}>
                                    <InputLabel style={webStyle.label}>Category</InputLabel>
                                    <MultipleSelectWithCheckBox options={[
                                        { id: '1', label: 'JFK' },
                                        { id: '2', label: 'LAX' },
                                        { id: '3', label: 'ORD' },
                                        { id: '4', label: 'DFW' },
                                    ]}
                                        placeholder="Select category"
                                    />
                                </Box>
                                <Box sx={webStyle.inputWrapper}>
                                    <InputLabel style={webStyle.label}>Class</InputLabel>
                                    <MultipleSelectWithCheckBox options={[
                                        { id: '1', label: 'JFK' },
                                        { id: '2', label: 'LAX' },
                                        { id: '3', label: 'ORD' },
                                        { id: '4', label: 'DFW' },
                                    ]}
                                        placeholder="Select class"
                                    />
                                </Box>
                                <Box sx={webStyle.inputWrapper}>
                                    <InputLabel style={webStyle.label}>Aircraft type</InputLabel>
                                    <MultipleSelectWithChips options={[
                                        { id: '1', label: 'JFK - John F. Kennedy International Airport' },
                                        { id: '2', label: 'LAX - Los Angeles International Airport' },
                                        { id: '3', label: 'ORD - O Hare International Airport' },
                                    ]} name='aircraftTypes' placeholder="Enter aircraft type" selectedItems={formValues.aircraftTypes} onItemsChange={onChangeItems} />
                                </Box>

                            </AccordionDetails>
                        </Accordion>

                    </Grid>

                    {/* Add a second accordion here if needed */}
                    <Grid item xs={6}>
                        <Accordion expanded={this.state.expanded} onChange={this.handleChanges} sx={{ boxShadow: 'none', border: 'none' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{
                                    width: '20px',
                                    height: '20px',
                                    marginLeft: '20px',
                                    opacity: '0px', color: '#5274B5', backgroundColor: 'rgb(227, 231, 238);',
                                    borderRadius: '50%'
                                }} />}
                                aria-controls="panel2-content"
                                // IconButtonProps={{edge: 'start'}}
                                id="panel2-header"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse', // Use row-reverse to position items
                                    alignItems: 'center',
                                    padding: 0,
                                    '& .MuiAccordionSummary-content': {
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }

                                    // fontFamily:'sans-serif'
                                }}
                            >
                                <Typography sx={{
                                    fontFamily: 'Montserrat,sans-serif',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    textAlign: 'left',
                                    color: '#5274B5'
                                }}>Tests</Typography>
                                <Typography sx={{
                                    fontFamily: 'Montserrat,sans-serif',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    textAlign: 'left',
                                    color: '#5274B5',
                                    height: '25px',
                                    width: '25px',
                                    backgroundColor: 'rgb(227, 231, 238)',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    justifySelf: 'end !important',
                                    marginLeft: '8px'
                                }}>6</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={webStyle.inputWrapper}>
                                    {formValues.addedAirCraftTypes.map((test: any, index: number) => (
                                        <Box key={test.id} sx={{
                                            display: 'flex', justifyContent: 'space-between',
                                            alignItems: 'center', marginBottom: '8px',
                                            border: '1px solid #CBD5E1',
                                            borderRadius: '15px',
                                            padding: '10px 10px',
                                            width: '360px'

                                        }}>
                                            <Typography sx={{
                                                fontFamily: 'Montserrat,sans-serif',
                                                fontSize: '16px',
                                                fontWeight: 500,
                                                lineHeight: '24px',
                                                textAlign: 'left',
                                                color: '#5274B5',
                                                height: '25px',
                                                width: '25px',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                justifySelf: 'end !important',
                                                margin: '0px 10px',
                                                border: '1px solid #94A3B8'
                                            }}>{index + 1}</Typography>
                                            <Typography sx={{
                                                fontFamily: 'Montserrat,sans-serif',
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                lineHeight: "24px",
                                                textAlign: 'left',

                                            }}>{test.value}</Typography>
                                            <IconButton onClick={() => this.handleDeleteClick(test.id)}>
                                                <DeleteOutlineIcon sx={{ color: '#F87171' }} />
                                            </IconButton>
                                        </Box>
                                    ))}
                                </Box>
                            </AccordionDetails>
                           
                        </Accordion>
                        {this.state.open && <Dialog PaperProps={{
                            style: {
                                width: '600px', // Set your desired width
                                height: '335px',
                                borderRadius: '10px'
                                // Set your desired height
                            },
                        }} open={this.state.open} onClose={this.handleCloseDialog} >
                            <DialogTitle sx={{
                                fontFamily: 'Montserrat,sans-serif',
                                fontSize: "22px",
                                fontWeight: 700,
                                lineHeight: "28px",
                                textAlign: 'left',
                                color: '#001C55',
                                padding: '20px 35px',
                                display: 'flex',
                                alignItems: 'center',

                            }}>
                                <img src={warning} alt='warning' style={{
                                    color: 'blue'
                                }} width={'35px'} height={'35px'} />
                                Remove item?</DialogTitle>
                            <DialogContent>
                                <Typography sx={{
                                    fontFamily: 'Montserrat,sans-serif',
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    textAlign: 'left',
                                    color: '#0F172A',
                                    padding: '5px 15px'
                                }}>This action will delete the item and all associated tests, but you can
                                    always add it again later if needed. Do you want to proceed?</Typography>

                                <FormControlLabel sx={{
                                    padding: '20px 10px',

                                }}
                                    control={
                                        <Checkbox
                                            checked={this.state.checked}
                                            onChange={this.handleChange}
                                            name="myCheckbox"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Typography
                                            sx={{
                                                fontFamily: 'Montserrat, sans-serif',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                lineHeight: '24px',
                                                textAlign: 'left',
                                                color: '#0F172A',
                                            }}
                                        >
                                            Don't show this again
                                        </Typography>
                                    }
                                />


                            </DialogContent>

                            <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>

                                <Button
                                    type="button"
                                    onClick={this.handleConfirmDelete}
                                    sx={{ ...webStyle.buttonStyle }} // Make button full width
                                    data-test-id="LoginBtn"
                                    variant="contained"
                                >
                                    Yes, remove item
                                </Button>
                                <Button
                                    type="button"
                                    onClick={this.handleConfirmDelete}
                                    sx={{
                                        ...webStyle.cancelbuttonStyle,
                                        '&:hover': {
                                            backgroundColor: 'white',
                                            color: '#0074BF',
                                        }
                                    }}
                                    data-test-id="LoginBtn"
                                    variant="contained"
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>}
                    </Grid>
                </Grid>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: formValues.addedAirCraftTypes.length > 0 ? 'center' : 'start' }}>
                    <Button
                        type="button"
                        onClick={onSubmit}
                        sx={{ ...webStyle.buttonStyle, width: '100%', maxWidth: formValues.addedAirCraftTypes.length > 0 ? '300px' : '100%' }} // Make button full width
                        data-test-id="LoginBtn"
                        variant="contained"
                    >
                        Continue
                    </Button>
                </Box>

                <Typography variant="h6" align="center" sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "#0F172A",
                    display: "inline"
                }}>
                    <Link to="/Login" style={{
                        fontFamily: 'Montserrat, sans-serif',
                        display: "inline",
                        fontWeight: 700,
                        fontSize: "16px",
                        color: "#0074BF",
                        textDecoration: "none",
                        marginLeft: "7px"
                    }}>
                        I’ll finish this later
                    </Link>
                </Typography>
            </Box>
        );
    }
}

const webStyle = {
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        borderRadius: '26px',
        border: '1px solid rgb(252, 250, 250)',
        backgroundColor: 'rgb(255, 255, 255)',
        padding: '30px',
    },
    inputWrapper: {
        position: "relative",
        width: "100%",
        maxWidth: "360px",
        mb: 1,
    },
    label: {
        fontSize: "16px",
        fontWeight: 600,
        marginTop: "3px",
        color: "#334155",
    },
    buttonStyle: {
        height: "50px",
        marginTop: "25px",
        width: "270px",
        marginBottom: "20px",
        border: "none",
        borderRadius: "20px",
        backgroundColor: "#0074BF",
        color: "white",
        cursor: "pointer",
        fontWeight: 700,
        textTransform: "none",
    },
    cancelbuttonStyle: {
        height: "50px",
        marginTop: "25px",
        width: "270px",
        marginBottom: "20px",
        borderRadius: "20px",
        cursor: "pointer",
        fontWeight: 700,
        color: '#0074BF',
        backgroundColor: "white",
        border: "1px solid #0074BF",
        textTransform: "none",
    },
};

export default AccountSetupStep2;
