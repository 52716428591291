import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isActivated: boolean;
   
}

// Customizable Area Start

// Customizable Area End

interface SS {
    // Customizable Area Start
    id: number;
    // Customizable Area End
}

export default class AccountStatusController  extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    loginApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            isActivated:false
            // Customizable Area End
        };


    }
    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        this.setState((state) => ({ ...state,  isActivated: !this.state.isActivated }));
    }
    // Customizable Area End
}
