import React from "react";

import {
    // Customizable Area Start
    Box,
    Typography,
    Button,
    Grid
    // Customizable Area End
} from "@mui/material";

import { loginLogo } from "./../../email-account-login/src/assets";
import { successIcon , searchIcon1} from "./assets";
import { Link } from 'react-router-dom';
import AccountStatusController, { Props } from "./accountStatusController";

export default class AccountStatus extends AccountStatusController {
    constructor(props: Props) {
        super(props);
     
    }
    render() {
        return (
            <>
                <div className="Toastify"></div>
                <Grid container direction={'row'} xs={12} sx={{
                    width: '100%',
                    height: '100%',

                }}>
                    <Grid item xs={12}>
                        <Grid sx={{
                            height: "12%", width: "100%",
                            backgroundColor: '#daf8ff',
                        }}>
                            <img src={loginLogo} style={{ padding: '7px', height: '68%', width: '18%' }} />
                        </Grid>
                        <Grid sx={
                            {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '90%',
                                backgroundColor: 'lightgray'
                            }
                        }>
                            {!this.state.isActivated ?
                                <Box sx={webStyle.formWrapper} data-test-id='account-inprogress'>
                                    <Box sx={{
                                        margin: '7px 18px', display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center', height: '120px', width: '120px', borderRadius: "50%", backgroundColor: '#daf8ff'
                                    }}>
                                        <img src={searchIcon1.default} style={{ height: '60px', width: '60px' }} alt="left-arrow" />
                                    </Box>
                                    <Box sx={webStyle.inputContainer}>
                                        <Typography variant="h6" sx={{
                                            fontFamily: 'Montserrat, sans-serif',
                                            fontWeight: 700, fontSize: "22px", color: "#001C55",
                                            textAlign: 'center'

                                        }}>
                                            Account under review                                                    </Typography>
                                        <Typography variant="h6" sx={{
                                            margin: '15px 0px',
                                            fontFamily: 'Montserrat, sans-serif',
                                            fontWeight: 400, fontSize: "16px", color: "#0F172A",
                                            textAlign: 'center'
                                        }}>
                                            You will receive a notification once your account is approved and activated.
                                            This may take up to 48 hours.    No worries, we'll send a recovery link to your email.
                                        </Typography>
                                    </Box>
                                    {/* <Link to="/Login" > */}
                                        <Button
                                            type="button"
                                            onClick={(event: any) => this.handleSubmit(event)}
                                            sx={webStyle.btnStyle}
                                            data-test-id="LoginBtn"
                                            variant="contained"
                                        >
                                            Back to login
                                        </Button>
                                    {/* </Link > */}
                                </Box>
                            :<Box sx={webStyle.formWrapper}>
                                <Box sx={{
                                    margin: '7px 18px', display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center', height: '120px', width: '120px', borderRadius: "50%", backgroundColor: '#daf8ff'
                                }}>
                                    <img src={successIcon.default} style={{ height: '60px', width: '60px' }} alt="left-arrow" />
                                </Box>
                                <Box sx={webStyle.inputContainer}>
                                    <Typography variant="h6" sx={{
                                        fontFamily: 'Montserrat, sans-serif',
                                        fontWeight: 700, fontSize: "22px", color: "#001C55",
                                        textAlign: 'center'

                                    }}>
                                        Welcome aboard!                                                   </Typography>
                                    <Typography variant="h6" sx={{
                                        margin: '15px 0px',
                                        fontFamily: 'Montserrat, sans-serif',
                                        fontWeight: 400, fontSize: "16px", color: "#0F172A",
                                        textAlign: 'center'


                                    }}>

                                        Your account has been successfully activated. You’re ready to start managing your availability and tests.
                                    </Typography>


                                </Box>
                                <Button
                                    type="button"
                                    onClick={(event: any) => this.handleSubmit(event)}
                                    sx={webStyle.btnStyle}
                                    data-test-id="GetStarted"
                                    variant="contained"
                                >
                                    Get Started
                                </Button>
                                <Typography variant="h6" align="center" sx={{
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontWeight: 400, fontSize: "16px", color: "#0F172A",
                                    display: "inline"
                                }}>

                                    <Link to="/Login" style={{
                                        fontFamily: 'Montserrat, sans-serif', display: "inline",
                                        fontWeight: 700, fontSize: "16px", color: "#0074BF", textDecoration: "none",
                                        marginLeft: "7px"
                                    }}>
                                        Log Out
                                    </Link >
                                </Typography>
                            </Box>
                            }
                        </Grid>
                    </Grid>

                </Grid >
            </>
            // Customizable Area End
        );
    }
}

const webStyle = {
    inputContainer: {
        position: "relative",
        width: "100%",
        maxWidth: "360px",
        mb: 1
    },
    btnStyle: {
        height: "50px",
        width: "360px",
        marginTop: "25px",
        marginBottom: "20px",
        border: "none",
        borderRadius: "20px",
        backgroundColor: "#0074BF",
        color: "white",
        cursor: "pointer",
        fontWeight: 700,
        textTransform: "none"
    },
    formWrapper: {
        // width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        // height: '350px'
        borderRadius: '26px',
        border: '1px solid rgb(252, 250, 250)',
        backgroundColor: 'rgb(255, 255, 255)',
        padding: '30px'
    },
};