import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedForm: string;
    accordform1Expanded: boolean;
    accordform2Expanded: boolean;
    step1Form: {
        airports: string[];

    }
    step2Form: {
        gradeOfCirtificates: string[];
        category: string[];
        class: string[];
        aircraftTypes: string[];
        addedAirCraftTypes: any[]
    }
}

interface Option {
    id: string;
    label: string;
}
// Customizable Area Start

// Customizable Area End

interface SS {
    // Customizable Area Start
    id: number;
    // Customizable Area End
}

export default class AccountStatusController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    loginApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // let role = new URLSearchParams(window.location.search).get('role')

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            selectedForm: 'form1',
            accordform1Expanded: false,
            accordform2Expanded: false,
            step1Form: {
                airports: [],
            },
            step2Form: {
                gradeOfCirtificates: [],
                category: [],
                class: [],
                aircraftTypes: [],
                addedAirCraftTypes: [
                    { id: '1', value: 'Private - Airplane - Single Engine Land' },
                    { id: '2', value: 'Commercial - Airplane - Multi Engine Land' },
                    { id: '3', value: 'Instrument Rating - Airplane' },
                    { id: '4', value: 'Flight Instructor - Airplane' },
                    { id: '5', value: 'Airline Transport Pilot - Airplane' },
                    { id: '6', value: 'Private - Helicopter' },
                
                ]
            }
            // Customizable Area End
        };
        // Customizable Area Start
        // Customizable Area End
    }

    // Customivalzable Area Start
    // Customizable Area End

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        const type = this.state.selectedForm === 'form1' ? 'form2' : this.state.selectedForm === 'form2' ? 'form3' : 'form1'
        this.setState((state) => ({ ...state, selectedForm: type }));
    }

    handleAccordform1Expanded = (name: string) => {
        if (name === "accordform1Expanded") {
            this.setState((state) => ({
                step1Form: {
                    ...state.step1Form,
                    accordform1Expanded: !this.state.accordform1Expanded
                },
            }));
        } else {
            this.setState((state) => ({

                step1Form: {
                    ...state.step1Form,
                    accordform2Expanded: !this.state.accordform2Expanded
                },
            }));
        }
    }


    handleStep1Change = (name: string, value: string[]) => {
        this.setState((state) => ({
            step1Form: {
                ...state.step1Form,
                [name]: value,
            },
        }));
    }
    handleStep2Change = (name: string, value: string[]) => {
        this.setState((state) => ({
            step2Form: {
                ...state.step2Form,
                [name]: value,
            },
        }));
    }

    // Customizable Area End
}
