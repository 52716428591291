// App.js - WEB
import React from 'react';
import firebase from 'firebase';
import { BuilderProvider } from '@builder/component-library';

import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';

import Login from "../../blocks/email-account-login/src/Login.web";
import Startup from "../../blocks/splashscreen/src/Startup";
import Signup from "../../blocks/email-account-registration/src/Signup.web";
import HomeScreen from '../../components/src/HomeScreen';

import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web";
import AccountStatus from "../../blocks/forgot-password/src/accountStatus.web";
import AccountSetup from "../../blocks/email-account-registration/src/AccounSetUp.web";

const routeMap = {
  Startup: {
    component: Startup,
    path: "/"
  },
  Login: {
    component: Login,
    path: "/Login"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/forgot-password"
  },
  ResetPassword: {
    component: ResetPassword,
    path: "/reset-password"
  },
  Signup: {
    component: Signup,
    path: "/Signup"
  },
  AccountStatus:{
     component: AccountStatus,
    path: "/account-status"
  },
  AccountSetup:{
    component: AccountSetup,
   path: "/account-setup"
 }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <WebRoutesGenerator routeMap={routeMap} />
    </BuilderProvider>
  );
}

export default App;