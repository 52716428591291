import React from "react";
import {
    Grid,
} from "@mui/material";
import { loginLogo } from "./../../email-account-login/src/assets";
import AccountSetupStep1 from "./AccountSetupStep1"
import AccountSetupStep2 from "./AccountSetUpStep2"
import AccountSetupStep3 from "./AccountSetUpStep3"
import AccountSetUpController, { Props } from "./AccountSetUpWebController";

export default class AccountStatus extends AccountSetUpController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <>
                <div className="Toastify"></div>
                <Grid container direction={'row'} xs={12} sx={{
                    width: '100%',
                    height: '100%',

                }}>
                    <Grid item xs={12}>
                        <Grid sx={{
                            height: "12%", width: "100%",
                            backgroundColor: '#daf8ff',
                        }}>
                            <img src={loginLogo} style={{ padding: '7px', height: '68%', width: '18%' }} />
                        </Grid>
                        <Grid sx={
                            {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '90%',
                                backgroundColor: 'lightgray'
                            }
                        }>
                            {this.state.selectedForm === 'form1' ?
                                <AccountSetupStep1 data-test-id='AccountSetupStep1' formValues={this.state.step1Form} onSubmit={(event: any) => this.handleSubmit(event)} onChangeItems={this.handleStep1Change} />
                                : this.state.selectedForm === 'form2' ?
                                    <AccountSetupStep2 data-test-id='AccountSetupStep2' handleAccordform1Expanded={this.handleAccordform1Expanded} accordform1Expanded={this.state.accordform1Expanded} accordform2Expanded={this.state.accordform1Expanded} formValues={this.state.step2Form} onSubmit={(event: any) => this.handleSubmit(event)} onChangeItems={this.handleStep2Change} /> :
                                    <AccountSetupStep3 data-test-id='AccountSetupStep3' handleAccordform1Expanded={this.handleAccordform1Expanded} accordform1Expanded={this.state.accordform1Expanded} accordform2Expanded={this.state.accordform1Expanded} formValues={this.state.step2Form} onSubmit={(event: any) => this.handleSubmit(event)} onChangeItems={this.handleStep2Change} />
                            }
                        </Grid>
                    </Grid>

                </Grid >
            </>
            // Customizable Area End
        );
    }
}

const webStyle = {
    inputWrapper: {
        position: "relative",
        width: "100%",
        maxWidth: "360px",
        mb: 1
    },
    recoveryButton: {
        height: "50px",
        width: "360px",
        marginTop: "25px",
        marginBottom: "20px",
        padding: '10px 20px',
        border: "2px solid #0074BF",
        backgroundColor: "#ffffff",
        color: "#0074BF",
        borderRadius: '20px',
        cursor: "pointer",
        fontWeight: 700,
        fontSize: "16px",
        textTransform: "none"
    },
    mainWrapper: {
        paddingBottom: "30px",
        display: "flex",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        flexDirection: "column",
        alignItems: "center",
        background: "#fff",
        justifyContent: "center",
        height: "100vh",
    },
    buttonStyle: {
        height: "50px",
        width: "360px",
        marginTop: "25px",
        marginBottom: "20px",
        border: "none",
        borderRadius: "20px",
        backgroundColor: "#0074BF",
        color: "white",
        cursor: "pointer",
        fontWeight: 700,
        textTransform: "none"
    },
    label: {
        fontSize: "16px",
        fontWeight: 600,
        marginTop: "3px",
        color: "#334155"
    },
    formWrapper: {
        // width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        // alignItems: "center",
        borderRadius: '26px',
        border: '1px solid rgb(252, 250, 250)',
        backgroundColor: 'rgb(255, 255, 255)',
        padding: '30px'
    },
};
