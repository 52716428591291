import React from 'react';
import {
  Autocomplete,
  Chip,
  TextField,
  Box,
  Checkbox,
  Typography,
  InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Import ExpandMoreIcon

interface Option {
  id: string; 
  label: string; 
}

interface MultipleSelectWithChipsProps {
  options: Option[];
  label?: string;
  placeholder?:string;
}

interface MultipleSelectWithChipsState {
  selectedItems: Option[]; 
}

class MultipleSelectWithCheckBox extends React.Component<MultipleSelectWithChipsProps, MultipleSelectWithChipsState> {
  constructor(props: MultipleSelectWithChipsProps) {
    super(props);
    this.state = {
      selectedItems: []
    };
  }

  handleChange = (event: any, value: Option[]) => {
    this.setState({ selectedItems: value });
  };

  handleDelete = (itemToDelete: Option) => {
    this.setState((prevState) => ({
      selectedItems: prevState.selectedItems.filter((item) => item.id !== itemToDelete.id)
    }));
  };

  clearSelections = () => {
    this.setState({ selectedItems: [] });
  };

  render() {
    const { selectedItems } = this.state;
    const { options, label } = this.props;

    return (
      <Box sx={{ width: 300 }}>
        <Autocomplete
          multiple
          options={options}
          getOptionLabel={(option) => option.label} 
          value={selectedItems}
          onChange={this.handleChange}
          disableCloseOnSelect
          sx={{
            '& .MuiInputBase-root': {
              border: '1px solid #CBD5E1',
              borderRadius: '25px',
              paddingRight: '30px !important', 
              '&:hover': {
                border: '1px solid blue',
              },
            },
            width: '360px',
            height: '50px',
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={this.props.placeholder}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <ExpandMoreIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&::placeholder': {
                  color: '#94A3B8',
                  opacity: 1,
                },
                
                '& .MuiOutlinedInput-root': {
                  paddingRight: 0, 
                },
              }}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                checked={selected}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
              <Typography>{option.label}</Typography>
            </li>
          )}
          renderTags={() => null} 
        />

        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selectedItems.map((value) => (
              <Chip
                sx={{
                  color: '#001C55',
                  height: '32px',
                  backgroundColor: 'E0F2F8',
                  '& .MuiChip-deleteIcon': {
                    height: '16px',
                    width: '16px',
                    color: "#001C55"
                  },
                }}
                key={value.id}
                label={value.label}
                onDelete={() => this.handleDelete(value)}
                deleteIcon={<CloseIcon />}
              />
            ))}
          </Box>
        </Box>
      </Box>
    );
  }
}

export default MultipleSelectWithCheckBox;
