import React from 'react';
import { Box, Button, Badge, FormControlLabel, TextField, Checkbox, DialogContent, Dialog, DialogTitle, DialogActions, IconButton, InputLabel, Typography, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import MultipleSelectWithChips from "../../../components/src/MultipleDropDown";
import { Link } from 'react-router-dom';
import { leftArrow, warning } from "./assets";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
interface Option {
  id: string;
  label: string;
}

interface AccountSetupStep1Props {
  formValues: any;
  onChangeItems: (name: string, value: string[]) => void;
  accordform1Expanded: boolean;
  accordform2Expanded: boolean;
  handleAccordform1Expanded: (name: string) => void;
  onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void; // Define the type for onSubmit

}
interface states {
  open: boolean,
  checked: boolean

}
class AccountSetupStep3 extends React.Component<AccountSetupStep1Props, states> {
  constructor(props: AccountSetupStep1Props) {
    super(props);
    this.state = {
      open: false,
      checked: false
    };
  }
  
 
 
  render() {
    const { formValues, onSubmit, onChangeItems, accordform1Expanded } = this.props;
    console.log(accordform1Expanded, "accordform1Expandedbbbbbbbbb")
    console.log(this.state, "this.state.openDialog")
    return (
      <Box sx={webStyle.formWrapper}>
        <Box sx={webStyle.inputWrapper}>
          <Typography variant="h6" sx={{
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 700,
            fontSize: "22px",
            color: "#001C55",
          }}>
            <img src={leftArrow} style={{ padding: '7px', height: '21px', width: '24px' }} />
            Time for instructions!
          </Typography>

          <Typography variant="h6" sx={{
            margin: '15px 0px',
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 400,
            fontSize: "16px",
            color: "#0F172A",
          }}>
            Add the final details your applicants need for each designation:
          </Typography>

        </Box>
        <Box sx={{ ...webStyle.inputWrapper }}>
          <InputLabel style={webStyle.label}>Designation</InputLabel>
          <MultipleSelectWithChips options={[
            { id: '1', label: 'JFK - John F. Kennedy International Airport' },
            { id: '2', label: 'LAX - Los Angeles International Airport' },
            { id: '3', label: 'ORD - O Hare International Airport' },
          ]} name='aircraftTypes' placeholder="Enter aircraft type" selectedItems={formValues.aircraftTypes} onItemsChange={onChangeItems} />
        </Box>
        <Box sx={{
          width: "100%", display: 'flex', justifyContent: 'space-between'

        }}>
          <Box sx={{
            width:'360px'
          }}>
            <InputLabel style={webStyle.label}>Exam cost</InputLabel>
            <TextField
              data-test-id={"exam cost"}
              name="email"
              id="email"
              // value={props.values.email}
              // onChange={(event) => {
              //   props.setFieldValue("email", event.target.value);
              //   this.onValueChange("email", event.target.value);
              // }}
              size="medium"
              fullWidth={true}
              variant="outlined"
              type="text"
              // onBlur={props.handleBlur}
              placeholder="e.g., $1,000.00"
              sx={{
                "&.Mui-focused": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #CBD5E1",
                    borderWidth: "1px",
                  },
                },
                backgroundColor: "#ffffff",
                color: "#94A3B8",
                mb: "3px",
              }}

              InputProps={{
                style: {
                  border: "1px solid #CBD5E1",
                  borderRadius: '20px',
                  fontWeight: 400,
                  // width: "360px"

                }
              }}
            />
            {/* {this.getErrorMessage(
                        props.touched,
                        props.errors,
                        "email"
                      )} */}
          </Box>
          <Box sx={{
            width:'360px'
            
          }}>
            <InputLabel style={webStyle.label}>Accepted payment methods</InputLabel>
            <TextField
              id="password"
              data-test-id={"password"}
              // value={props.values.password}
              // onChange={(event) => {
              //   props.setFieldValue("password", event.target.value);
              //   this.onValueChange("password", event.target.value);
              // }}
              size="medium"
              name="password"
              // onBlur={props.handleBlur}
              variant="outlined"
              placeholder="e.g., Credit Card, PayPal, Cash"
              fullWidth={true}
              type={"password"}
              sx={{
                "&.Mui-focused": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #CBD5E1",
                    borderWidth: "1px",
                  },
                },
                backgroundColor: "#ffffff",
                color: "#94A3B8",
                mb: "3px",
              }}

              InputProps={{
                style: {
                  border: "1px solid #CBD5E1",
                  // width: "360px",
                  borderRadius: '20px',
                  fontWeight: 400
                }
              }}
            />
          </Box>
        </Box>

        {/* Add a second accordion here if needed */}
        <Box sx={webStyle.inputWrapper}>
          <InputLabel style={webStyle.label}>Exam cost1</InputLabel>
          <TextField
            data-test-id={"exam cost1"}
            name="email"
            rows={7}
            multiline
            // value={props.values.email}
            // onChange={(event) => {
            //   props.setFieldValue("email", event.target.value);
            //   this.onValueChange("email", event.target.value);
            // }}
            size="medium"
            fullWidth={true}
            variant="outlined"
            type="text"
            // onBlur={props.handleBlur}
            placeholder="Enter detailed instructions or guidelines for the designation here"
            sx={{
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #CBD5E1",
                  borderWidth: "1px",
                },
              },
              backgroundColor: "#ffffff",
              color: "#94A3B8",
              mb: "3px",
            }}

            InputProps={{
              style: {
                border: "1px solid #CBD5E1",
                borderRadius: '20px',
                fontWeight: 400,
                // width: "360px"

              }
            }}
          />
          {/* {this.getErrorMessage(
                        props.touched,
                        props.errors,
                        "email"
                      )} */}
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: formValues.addedAirCraftTypes.length > 0 ? 'center' : 'start' }}>
          <Button
            type="button"
            onClick={onSubmit}
            sx={{ ...webStyle.buttonStyle, width: '100%', maxWidth: formValues.addedAirCraftTypes.length > 0 ? '300px' : '100%' }} // Make button full width
            data-test-id="LoginBtn"
            variant="contained"
          >
            Continue
          </Button>
        </Box>

        <Typography variant="h6" align="center" sx={{
          fontFamily: 'Montserrat, sans-serif',
          fontWeight: 400,
          fontSize: "16px",
          color: "#0F172A",
          display: "inline"
        }}>
          <Link to="/Login" style={{
            fontFamily: 'Montserrat, sans-serif',
            display: "inline",
            fontWeight: 700,
            fontSize: "16px",
            color: "#0074BF",
            textDecoration: "none",
            marginLeft: "7px"
          }}>
            I’ll finish this later
          </Link>
        </Typography>
      </Box>
    );
  }
}

const webStyle = {
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    borderRadius: '26px',
    border: '1px solid rgb(252, 250, 250)',
    backgroundColor: 'rgb(255, 255, 255)',
    padding: '30px',
    width: '940px',
    height: '880px'

  },
  inputWrapper: {
    position: "relative",
    width: "100%",
    mb: 1,
  },
  label: {
    fontSize: "16px",
    fontWeight: 600,
    marginTop: "3px",
    color: "#334155",
  },
  buttonStyle: {
    height: "50px",
    marginTop: "25px",
    marginBottom: "20px",
    border: "none",
    borderRadius: "20px",
    backgroundColor: "#0074BF",
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    textTransform: "none",
  },
};

export default AccountSetupStep3;
