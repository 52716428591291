import React from 'react';
import {
    Autocomplete,
    Chip,
    TextField,
    Box, Button,
    InputAdornment, Typography
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
// import { cos } from 'react-native-reanimated';

interface Option {
    id: string;
    label: string;
}

interface MultipleSelectWithChipsProps {
    options: Option[];
    placeholder: string;
    selectedItems: string[];
    onItemsChange: (name: string, items: string[]) => void;
    name: string;
    
}

interface MultipleSelectWithChipsState {
    inputValue: string
}

class MultipleSelectWithChips extends React.Component<MultipleSelectWithChipsProps, MultipleSelectWithChipsState> {
    constructor(props: MultipleSelectWithChipsProps) {
        super(props);
        this.state = {
            inputValue: ''
        };
    }

    handleChange = (event: any, value: Option[]) => {
        // event.preventDefault()
        console.log(value, "kkkkkkkk")
        const selectedIds: string[] = value.map(item => item.id);
        this.props.onItemsChange(this.props.name, selectedIds); // Call the parent's onItemsChange with the updated value
    };
    handleInputChange = (event: any, value: string) => {
        console.log(value, "textinput")
        this.setState({ inputValue: value });
    };
    handleDelete = (itemToDelete: Option) => {

        const updatedItems = this.props.selectedItems.filter(item => item !== itemToDelete.id);
        this.props.onItemsChange(this.props.name, updatedItems);
    };

    clearSelections = () => {
        this.props.onItemsChange(this.props.name, []);
    };

    handleAddNewOption = () => {
        const { inputValue } = this.state;
        console.log("added ", inputValue)
    };

    render() {
        const { inputValue } = this.state;
        const { selectedItems, options, placeholder ,name} = this.props;
        const selectedOptions = options.filter((option: any) => {
            return selectedItems.includes(option.id)
        });
     
        return (
            <Box >
                <Autocomplete
                    multiple
                    options={options}
                    getOptionLabel={(option) => option.label}
                    value={selectedOptions}
                    onChange={this.handleChange}
                    disableCloseOnSelect
                    inputValue={inputValue}
                    onInputChange={this.handleInputChange}
                    clearOnBlur={false} 
                    noOptionsText={
                        <>
                            <Typography>No results found for '{inputValue}'</Typography>
                            {inputValue &&name==='aircraftTypes'&&
                             (
                                <Typography
                                    // variant="outlined" 
                                    onClick={this.handleAddNewOption}
                                    sx={{
                                        borderTop: '0.01px solid #CBD5E1', padding: '5px 10px 5px 10px',
                                        fontFamily: 'Montserrat, sans-serif',
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        lineHeight: "24px",
                                        textAlign: "left",

                                    }}
                                >
                                    Add '{inputValue}'
                                </Typography>
                            )}
                        </>
                    } // Dynamic no options text
                    // renderOption={(props, option) => {
                    //     console.log(option, "optionggggggggggggg")
                    //     return (
                    //         <Box
                    //             component="li"
                    //             {...props}
                    //             sx={{
                    //                 padding: '10px 3px 10px 3px',
                    //                 '&:hover': {
                    //                     backgroundColor: '#E0F2F8',
                    //                     cursor: 'pointer',
                    //                 },
                    //                 fontFamily: 'Montserrat, sans-serif',
                    //                 fontSize: '16px',
                    //                 lineHeight: '24px',
                    //             }}
                    //         >
                    //             {option.label}
                    //         </Box>
                    //     )
                    // }}
                    renderOption={(props, option) => {
                        const isSelected = selectedItems.includes(option.id);
                        return (
                            <Box
                                component="li"
                                {...props}
                                sx={{
                                    padding: '10px 3px 10px 3px',
                                    '&:hover': {
                                        backgroundColor: '#E0F2F8',
                                        cursor: 'pointer',
                                    },
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {isSelected && (
                                    <CheckIcon sx={{ color: '#007aff', marginRight: 1 }} />
                                )}
                                {option.label}
                            </Box>
                        );
                    }}
                    sx={{
                        '& .MuiInputBase-root': {
                            border: '1px solid #CBD5E1',
                            '&:hover': {
                                border: '1px solid blue',
                                borderColor: 'darkgray',
                            },
                        },
                        width: '100%',
                        height: '50px',
                        padding: '10px 0px 10px 0px',
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder} // Use placeholder prop
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: null,
                                sx: {
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '&::placeholder': {
                                        color: '#94A3B8',
                                        opacity: 1,
                                    },
                                    borderRadius: '20px',
                                },
                            }}
                        />
                    )}
                    // renderTags={() => null} // Prevent tags from showing in the input
                />


            
            </Box>
        );
    }
}
export default MultipleSelectWithChips