import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  name: string;
  dms: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
  consent: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;
  privacyPolicyOpen: boolean;
  termsOpen: boolean;
  errors: {
    [key: string]: string;
  };
  passwordStrength: number;
  isValid: boolean
}

export interface SS {
  id: any;
}

export interface PasswordRequirement {
  text: string;
  regex?: RegExp;
  test?: (value: string) => boolean;
}

export default class SignupController extends BlockComponent<Props, S, SS> {
  validationApiCallId: string = "";
  createAccountApiCallId: string = "";
  passwordRequirements: PasswordRequirement[];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // let role = new URLSearchParams(window.location.search).get('role')
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      name: "",
      dms: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
      consent: false,
      showPassword: false,
      showConfirmPassword: false,
      privacyPolicyOpen: false,
      termsOpen: false,
      errors: {},
      passwordStrength: 0,
      isValid: false
    };

    this.passwordRequirements = [
      { text: "At least one capital letter", regex: /[A-Z]/ },
      { text: "At least one lowercase letter", regex: /[a-z]/ },
      { text: "At least one number", regex: /\d/ },
      { text: "At least one special character", regex: /[!@#$%^&*(),.?":{}|<>]/ },
      { text: "Minimum character length is 8 characters", test: (value: string) => value.length >= 8 }
    ];


    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
  }

  handleInputChange = (field: keyof S, value: string | boolean) => {
    if (field === 'phone') {
      value = this.formatPhoneNumber(value as string);
    }
    if (field === 'dms') {
      value = (value as string).replace(/\D/g, '');
    }
    this.setState((prevState) => {
      const newState = { ...prevState, [field]: value };
      if (field === 'password') {
        this.checkPasswordStrength(value as string);
      }
      this.validateField(field, value);
      return newState;
    });
  }
  formatPhoneNumber = (value: string) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    if (cleaned.length <= 3) {
      return cleaned; // Return as is for first 3 digits
    } else if (cleaned.length <= 6) {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`; // Format as XXX-XXX
    } else {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`; // Format as XXX-XXX-XXXX
    }
  }
  togglePasswordVisibility = (field: 'showPassword' | 'showConfirmPassword') => {

    this.setState((prevState) => ({
      ...prevState,
      [field]: !prevState[field]
    }));
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("in submit")
    if (this.validateForm()) {
      console.log("in if condition")
      this.createAccount();
    }
  }

  getPasswordRequirementIconStyle = (requirement: PasswordRequirement, password: string) => {

    const webStyle = {
      checkIcon: {
        color: '#34D399',
        fontSize: '16px',
        marginRight: '8px',
      },
      uncheckIcon: {
        color: '#9E9E9E',
        fontSize: '16px',
        marginRight: '8px',
      },
    };
    const isMet = requirement.regex
      ? requirement.regex.test(password)
      : requirement.test
        ? requirement.test(password)
        : true;
    return isMet ? webStyle.checkIcon : webStyle.uncheckIcon;
  }

  validateField = (field: keyof S, value: string | boolean) => {
    let errors = { ...this.state.errors };

    switch (field) {
      case 'name':
        if (value) {
          if (/^[A-Za-z\s]+$/.test(value as string)) {
            errors.name = '';
          } else {
            errors.name = 'Name should contain alphabetic characters';
          }
        } else {
          errors.name = 'Name is required';
        }
        break;
      case 'dms':
        errors.dms = /^\d{9}$/.test(value as string) ? '' : 'DMS Number should contain 9 digits';
        break;
      case 'phone':
        errors.phone = /^\d{3}-\d{3}-\d{4}$/.test(value as string)
          ? ''
          : 'Please enter a valid phone number.';
        break;
      case 'email':
        errors.email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value as string) ? '' : 'Invalid email address';
        break;
      case 'password':
        errors.password = (value as string).length >= 8 ? '' : 'Password must be at least 8 characters';
        break;
      case 'confirmPassword':
        errors.confirmPassword = value === this.state.password ? '' : 'Passwords do not match';
        break;
      case 'consent':
        errors.consent = value ? '' : 'You must accept the terms and conditions';
        break;
    }

    this.setState({ errors });
  }
  validateForm = () => {
    let isValid = true;
    let errors = { ...this.state.errors };

    (Object.keys(this.state) as Array<keyof S>).forEach((key) => {
      if (key !== 'errors' && key !== 'showPassword' && key !== 'showConfirmPassword' &&
        key !== 'privacyPolicyOpen' && key !== 'termsOpen' && key !== 'passwordStrength') {
        const value = this.state[key];
        if (typeof value === 'string' || typeof value === 'boolean') {
          this.validateField(key, value);
          if (errors[key]) isValid = false;
        }
      }
    });

    this.setState({ errors });
    return isValid;
  }

  checkPasswordStrength = (password: string) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (password.match(/[a-z]+/)) strength++;
    if (password.match(/[A-Z]+/)) strength++;
    if (password.match(/\d+/)) strength++;
    if (password.match(/[$@#&!]+/)) strength++;

    this.setState({ passwordStrength: strength });
  }

  createAccount = () => {


    const headers = {
      "Content-Type": "application/json",
    };

    const [firstName, ...lastNameParts] = this.state.name.split(' ');
    const lastName = lastNameParts.join(' ');
    const { name, dms, phone, email, password } = this.state;

    console.log("full_phone_num",phone.replace(/[^+\d-]/g, ''));
    console.log('phone===========>',phone);
    
    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          first_name: firstName,
          last_name: lastName,
          user_name: name,
          email: email,
          password: password,
          full_phone_number: `+1${phone.replace(/[^+\d-]/g, '')}`,
          date_of_birth: "2000-06-29",
          country_code: 1,
          phone_number: phone.replace(/\D/g, ''),
          activated: true,
          ftn: dms,
          role_id: 2
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  setPrivacyPolicyOpen = (open: boolean) => {
    this.setState({ privacyPolicyOpen: open });
  }

  setTermsOpen = (open: boolean) => {
    this.setState({ termsOpen: open });
  }
}
// Customizable Area End